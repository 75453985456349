// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-podcast-js": () => import("./../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-episode-js": () => import("./../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-person-js": () => import("./../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bookbytes-giveaway-js": () => import("./../src/pages/bookbytes/giveaway.js" /* webpackChunkName: "component---src-pages-bookbytes-giveaway-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thankyou-js": () => import("./../src/pages/contact/thankyou.js" /* webpackChunkName: "component---src-pages-contact-thankyou-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shows-js": () => import("./../src/pages/shows.js" /* webpackChunkName: "component---src-pages-shows-js" */),
  "component---src-pages-sponsor-index-js": () => import("./../src/pages/sponsor/index.js" /* webpackChunkName: "component---src-pages-sponsor-index-js" */)
}

